/**
 * @file It contains scripts for Decision Component
 */
export default {
  name: "Decision",
  data: () => ({
    state  : undefined,
    actions: undefined
  }),
  props: {
    pCardProps       : Object,
    pCardActionsProps: Object,
    pActions         : Array,
    pYesActions      : Array,
    pNoActions       : Array
  },
  created() {
    this.setState(1)
  },
  methods: {
    setState(value) {
      switch(value) {
        case 1: {
          this.state   = 1
          this.actions = this.pActions
          break
        }
        case 2: {
          this.state   = 2
          this.actions = this.pYesActions
          break
        }
        case 3: {
          this.state   = 3
          this.actions = this.pNoActions
          break
        }
      }
    },
    async handleClick() {
      const caller = arguments[0]
      switch(caller) {
        case "button_yes": {
          if (this.pYesActions) {
            this.setState(2)
          } else {
            this.$emit("yes")
          }
          break
        }
        case "button_confirm_yes": {
          this.$emit("yes")
          break
        }
        case "button_no": {
          if (this.pNoActions) {
            this.setState(3)
          } else {
            this.$emit("no")
          }
          break
        }
        case "button_confirm_no": {
          this.$emit("no")
          break
        }
        case "button_cancel_yes":
        case "button_cancel_no" : {
          this.setState(1)
          break
        }
      }
    }
  }
}