var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"data-cy":"1592","fluid":""}},[_c('v-row',{attrs:{"data-cy":"1593"}},[_c('v-col',{attrs:{"data-cy":"1594"}},[_c('div',{staticClass:"d-block mb-4",attrs:{"data-cy":"1595"}},[_c('span',{attrs:{"data-cy":"1596"}},[_vm._v(_vm._s(_vm.$t("731")))]),_c('span',{ref:"link_fields",staticClass:"secondary--text link",attrs:{"data-cy":"1597"},on:{"click":function($event){return _vm.$router.push({ name: 'fields' })}}},[_vm._v(_vm._s(_vm.$t("732")))])]),_c('v-row',{attrs:{"data-cy":"1629"}},[_c('v-col',{attrs:{"data-cy":"1630"}},[_c('v-btn',{ref:"button_add_option_list",staticClass:"float-right mb-4",attrs:{"data-cy":"1631","color":"primary"},on:{"click":function($event){_vm.showAddOptionListDialog = true}}},[_c('v-icon',{attrs:{"data-cy":"1632","left":""}},[_vm._v("mdi-plus")]),_vm._v(_vm._s(_vm.$t("754")))],1)],1)],1),_c('v-data-table',{ref:"table_option_lists",staticClass:"elevation-1",attrs:{"data-cy":"1598","item-key":"id","items":_vm.optionListsForTable,"headers":_vm.headersForTable,"loading":_vm.isLoadingOptionLists || _vm.isLoadingOptionListItems,"footers-props":_vm.footersForTable,"items-per-page":_vm.$TABLES.OPTION_LISTS.itemsPerPage,"sort-by":"id","sort-desc":"","multi-sort":""},on:{"click:row":function (item) { return _vm.$router.push({ name: 'option-list', params: { id: item.id } }); }},scopedSlots:_vm._u([{key:"item.optionListItems",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-block text-truncate max-width-400",attrs:{"data-cy":"1599"}},on),[_c('span',{staticClass:"info--text",attrs:{"data-cy":"1600"}},[_vm._v(_vm._s(item.optionListItems))])])]}}],null,true)},[_c('span',{attrs:{"color":"info darken-4","data-cy":"1601"}},[_vm._v(_vm._s(item.optionListItems))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"float-right",attrs:{"data-cy":"1602","icon":""}},[_c('v-icon',{attrs:{"data-cy":"1603"}},[_vm._v("mdi-chevron-right")])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"data-cy":"1909"}},[_vm._v(_vm._s(item.name))]),(item.systemDefault)?_c('span',{staticClass:"font-italic info--text ml-1",attrs:{"data-cy":"1910"}},[_vm._v(_vm._s(_vm.$t("1017")))]):_vm._e()]}}])})],1)],1),_c('v-dialog',{attrs:{"data-cy":"1633","value":_vm.showAddOptionListDialog,"width":"600","height":"400","persistent":""}},[_c('validation-observer',{attrs:{"data-cy":"1634"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{attrs:{"data-cy":"1635"},on:{"submit":function($event){$event.preventDefault();handleSubmit(function () { return _vm.addOptionList({name:_vm.optionListName}); })}}},[_c('v-card',{staticClass:"pa-2",attrs:{"data-cy":"1636"}},[_c('v-card-title',{staticClass:"mb-4 info--text",attrs:{"data-cy":"1637"}},[_vm._v(_vm._s(_vm.$t("755")))]),_c('v-card-text',{attrs:{"data-cy":"1638"}},[_c('validation-provider',{ref:"option_list_name_validation_provider",attrs:{"data-cy":"1639","rules":("duplicate:" + _vm.isOptionListNameDuplicate + "|max:" + _vm.optionListNameCharacterLimit),"name":"756"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"input_option_list_name",attrs:{"data-cy":"1640","color":"primary","label":_vm._f("required")(_vm.$t('756')),"placeholder":_vm.$t('757'),"disabled":_vm.isAddingOptionList,"error-messages":errors,"outlined":""},model:{value:(_vm.optionListName),callback:function ($$v) {_vm.optionListName=$$v},expression:"optionListName"}})]}}],null,true)})],1),_c('v-card-actions',{staticClass:"justify-end",attrs:{"data-cy":"1641"}},[_c('v-btn',{ref:"button_cancel",attrs:{"data-cy":"1642","text":""},on:{"click":_vm.cancelOptionListAdd}},[_vm._v(_vm._s(_vm.$t("50"))+" ")]),_c('v-btn',{ref:"button_add",staticClass:"float-right",attrs:{"data-cy":"1643","color":"primary","type":"submit","disabled":!_vm.isOptionListNameValid,"loading":_vm.isAddingOptionList}},[_vm._v(_vm._s(_vm.$t("44")))])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }