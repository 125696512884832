import TABLES from "@/constants/tables"

/**
 * This method will return headers for the table based on the input received.
 * @param tableName contains the name of the data table
 * @param translate function to access locale values
 * @param excludedColumns array of columns names which needs to be excluded from the result
 */
export const getHeadersForTable = (tableName, translate, excludedColumns) => {
  let tableHeaders

  if (excludedColumns) {
    tableHeaders = TABLES[tableName].headers.filter(header =>
      !excludedColumns.includes(header.value)
    )
  } else {
    tableHeaders = TABLES[tableName].headers
  }

  return tableHeaders.map(header => {
    return {
      ...header, ...{
        text: translate(header.text)
      }
    }
  })
}

/**
 * This method will return footers for the table based on the input received.
 * @param tableName contains the name of the data table
 * @param translate function to access locale values
 */
export const getFootersForTable = (tableName, translate) => {
  return {
    ...TABLES[tableName].footer, ...{
      itemsPerPageText: translate(TABLES[tableName].footer.itemsPerPageText)
    }
  }
}